<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="wallets !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <b-col class="mb-1" cols="12" md="12">
              <h1>Manage Wallets</h1>
            </b-col>
            <!-- Search -->
            <b-col
                cols="12"
                md="4"
            >
                <b-form-group label="Search By">
                  <Debouncer :placeHolder="`search...`" @setValue="setSearch"></Debouncer>
                </b-form-group>
            </b-col>

            <b-col
                class="   mb-1 mb-md-0"
                cols="12"
                md="3"
            >
              <b-form-group label="Count">

              <v-select
                  v-model="perPage"
                  :clearable="false"
                  :options="perPageOptions"
                  class="per-page-selector w-75 mx-50"
              />
              </b-form-group>
            </b-col>

            <!--            <b-col-->
            <!--                class="d-flex align-items-center justify-content-start mt-1 mt-md-0"-->
            <!--                cols="12"-->
            <!--                md="2"-->
            <!--            >-->
            <!--              <b-button-->
            <!--                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"-->
            <!--                  class=""-->
            <!--                  variant="success"-->
            <!--                  @click="downloadFile"-->
            <!--              >-->
            <!--                download excel-->
            <!--              </b-button>-->
            <!--            </b-col>-->
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="wallets"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            sort-by.sync
            striped
        >

          <template #cell(userName)="data">
            <b-media vertical-align="center">
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.userName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>
          </template>

          <template #cell(updatedAt)="data">
            <span>{{
                new Date(data.item.updatedAt).toLocaleDateString('en-US')
              }} - {{ new Date(data.item.updatedAt).toLocaleTimeString('en-US') }}</span>
          </template>
          <template #cell(balance)="data">
            <p>{{ data.item.balance }} $</p>
          </template>
          <template #cell(fundInHold)="data">
            <p>{{ data.item.fundInHold }} $</p>
          </template>
          <template #cell(cashBack)="data">
            <p>{{ data.item.cashBack }} $</p>
          </template>
          <template #cell(operation)="data">
            <b-link
                :to="{ name: 'apps-wallets-wallet-info', params: { id: data.item.walletId } }"
                class="font-weight-bold"
            >
              <feather-icon class="text-primary ml-1" icon="EyeIcon" size="20"></feather-icon>

            </b-link>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { WalletGetAllRequest } from '@/libs/Api/Wallet'
import Helper from '@/libs/Helper'
import { getUserData } from '@/auth/utils'
import axiosIns from '@/libs/axios'
import { saveAs } from 'file-saver'
import Debouncer from '@/views/components/debouncer.vue'

export default {
  title: 'Wallets List',
  name: 'WalletsList',
  data() {
    return {
      wallets: null,
      totalCount: null,
      showOverlay: false,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      perPage: 10,
      CategoryName: '',
      TicketType: 0,
      state: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'userName',
          label: 'User',
          sortable: true
        },
        {
          key: 'fundInHold',
          label: 'fund In Hold',
          sortable: true
        },
        {
          key: 'balance',
          label: 'Balance',
          sortable: true
        },
        {
          key: 'cashBack',
          label: 'cashBack amount',
          sortable: true
        },
        {
          key: 'updatedAt',
          label: 'Last Update'
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      searchCommand: '',
      market: '',
      pageNumber: 1,
      count: 10,
      SelectedTicket: null,
    }
  },
  async created() {
    await this.GetAllWallets()
  },
  watch: {
    searchCommand: function () {
      this.GetAllWallets()
    },
    currentPage: function () {
      this.GetAllWallets()
    },
  },
  methods: {
    setSearch(search) {
      this.searchCommand = search
    },
    async GetAllWallets() {
      let _this = this
      _this.showOverlay = true
      let walletGetAllRequest = new WalletGetAllRequest(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.count,
        searchCommand: _this.searchCommand
      }
      walletGetAllRequest.setParams(data)
      await walletGetAllRequest.fetch(function (content) {
        _this.wallets = content.data.data
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async downloadFile() {
      let _this = this
      _this.overlay = true
      let userToken = getUserData().data.token

      await axiosIns.post(`Wallet/GetAllExportExcel`, null, {
        responseType: 'blob',
        'Authorization': userToken,
      })
          .then(resp => {
            _this.overlay = false
            saveAs(resp.data, 'wallets.xlsx')
          })
          .catch((error) => {
            _this.overlay = false
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: error.message,
                title: `error`,
                variant: 'danger',
              },
            })
          })
    },
  },
  components: {
    Debouncer,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
}
</script>

<style scoped>

</style>
